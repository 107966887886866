import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { Constantes } from 'src/app/utils/Constantes';

@Component({
    selector: 'app-detail-product',
    templateUrl: './detail-product.component.html',
    styleUrls: ['./detail-product.component.scss']
})
export class DetailProductComponent {

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];
    dataProduct;

    constructor(private route: ActivatedRoute) {};
 
    ngOnInit(): void {

        this.route.params.subscribe(params => {
            this.getProducto(params['id']);
        });
 
        this.galleryOptions = [
            {
                width: '100%',
                height: '80vh',
                imageAnimation: NgxGalleryAnimation.Zoom,
                previewCloseOnEsc: true,
                previewCloseOnClick: true,
                "imageSize": "contain" ,
                "previewFullscreen": true, 
                "previewForceFullscreen": true,
                "thumbnails": false,
                "imageArrows": false,
                imageSwipe: true,
                thumbnailsSwipe: true,
                previewSwipe: true 
                /*imageAutoPlayInterval: 3000, 
                thumbnailsColumns: 0,
                imageAutoPlay: true, 
                imageAutoPlayPauseOnHover: true, 
                previewAutoPlay: false,
                thumbnailsArrows: true,*/
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '50vh',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];
    }

    getProducto(id: number){
        this.dataProduct = Constantes.productos[id-1];
        document.getElementById("descripcion-p").innerHTML = this.dataProduct.des;

        let listaProductos = Constantes.productos;
        let imgProducto = listaProductos[id-1].img;

        this.galleryImages.push(
            {
                small:  'assets/' + imgProducto,
                medium: 'assets/' + imgProducto,
                big:    'assets/' + imgProducto
            }
        ) 
        /*for (let index = 0; index < 5; index++) {
            const numberImg = index + 1;
            //small:  'assets/product' + id + '-' + numberImg + '.jpeg',
            this.galleryImages.push(
                {
                    small:  'assets/product1-' + numberImg + '.jpeg',
                    medium: 'assets/product1-' + numberImg + '.jpeg',
                    big:    'assets/product1-' + numberImg + '.jpeg'
                }
            )
        }*/

    }
}
