import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgxGalleryModule } from 'ngx-gallery';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './components/utils/footer/footer.component';
import { UsSectionComponent } from './components/sections/us-section/us-section.component';
import { ProductsSectionComponent } from './components/sections/products-section/products-section.component';
import { EvidenceSectionComponent } from './components/sections/evidence-section/evidence-section.component';
import { InicioSectionComponent } from './components/sections/inicio-section/inicio-section.component';
import { DetailProductComponent } from './components/detail/detail-product/detail-product.component';
import { DetailEvidenceComponent } from './components/detail/detail-evidence/detail-evidence.component';
import { ContactSectionComponent } from './components/sections/contact-section/contact-section.component';
import { NavBarComponent } from './components/utils/nav-bar/nav-bar.component';
import { LinksMenuComponent } from './components/utils/links-menu/links-menu.component';
import { ImageGalleryComponent } from './components/utils/image-gallery/image-gallery.component';
import { ServiceSectionComponent } from './components/sections/service-section/service-section.component';
import { DetailServiceComponent } from './components/detail/detail-service/detail-service.component';
import { PromoComercialComponent } from './components/utils/promo-comercial/promo-comercial.component';
import { BackgroundWhiteComponent } from './components/utils/background-white/background-white.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    UsSectionComponent,
    ProductsSectionComponent,
    EvidenceSectionComponent,
    InicioSectionComponent,
    DetailProductComponent,
    DetailEvidenceComponent,
    ContactSectionComponent,
    NavBarComponent,
    LinksMenuComponent,
    ImageGalleryComponent,
    ServiceSectionComponent,
    DetailServiceComponent,
    PromoComercialComponent,
    BackgroundWhiteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxGalleryModule,
    ReactiveFormsModule
  ],
  providers: [{provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
