import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Constantes } from 'src/app/utils/Constantes';

@Component({
  selector: 'app-products-section',
  templateUrl: './products-section.component.html',
  styleUrls: ['./products-section.component.scss']
})

export class ProductsSectionComponent {

    paginaCompleta : Boolean = true;
    listaProductos;
    
    constructor(@Inject(DOCUMENT) document: any) {
        //console.log(document.location.href);
    }

    ngOnInit() {
        let url = document.location.href;
        this.listaProductos = Constantes.productos;

        if(!url.includes("/productos")){
            this.paginaCompleta = false;
            this.listaProductos = this.listaProductos.slice(0,4);
        }   
    }
}
