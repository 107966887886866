import { Component } from '@angular/core';

@Component({
  selector: 'app-us-section',
  templateUrl: './us-section.component.html',
  styleUrls: ['./us-section.component.scss']
})
export class UsSectionComponent {

}
