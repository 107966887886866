import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { DetailEvidenceComponent } from './components/detail/detail-evidence/detail-evidence.component';
import { DetailProductComponent } from './components/detail/detail-product/detail-product.component';
import { InicioSectionComponent } from './components/sections/inicio-section/inicio-section.component';
//import { NavBarComponent } from './components/utils/nav-bar/nav-bar.component';
import { UsSectionComponent } from './components/sections/us-section/us-section.component';
import { ProductsSectionComponent } from './components/sections/products-section/products-section.component';
import { ServiceSectionComponent } from './components/sections/service-section/service-section.component';
import { DetailServiceComponent } from './components/detail/detail-service/detail-service.component';
//import { EvidenceSectionComponent } from './components/sections/evidence-section/evidence-section.component';
import { ContactSectionComponent } from './components/sections/contact-section/contact-section.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  { path: 'inicio', component: InicioSectionComponent },
  { path: 'nosotros', component: UsSectionComponent },
  { path: 'detalleProducto/:id', component: DetailProductComponent },
//   { path: 'detalleObra/:id', component: DetailEvidenceComponent },
  { path: 'detalleServicio/:id', component: DetailServiceComponent },
  { path: 'productos', component: ProductsSectionComponent },
  { path: 'servicios', component: ServiceSectionComponent },
  { path: 'contacto', component: ContactSectionComponent },
//   { path: 'obras', component: EvidenceSectionComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],
    onSameUrlNavigation: "reload"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
