import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { Constantes } from 'src/app/utils/Constantes';

@Component({
  selector: 'app-detail-service',
  templateUrl: './detail-service.component.html',
  styleUrls: ['./detail-service.component.scss']
})
export class DetailServiceComponent implements OnInit {
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];
    dataService;

    constructor(private route: ActivatedRoute) {};
 
    ngOnInit(): void {

        this.route.params.subscribe(params => {
            this.getProducto(params['id']);
        });
 
        this.galleryOptions = [
            {
                width: '100%',
                height: '80vh',
                imageAnimation: NgxGalleryAnimation.Zoom,
                previewCloseOnEsc: true,
                previewCloseOnClick: true,
                "imageSize": "contain" ,
                "previewFullscreen": true, 
                "previewForceFullscreen": true,
                "thumbnails": false,
                "imageArrows": false,
                imageSwipe: true,
                thumbnailsSwipe: true,
                previewSwipe: true 
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '50vh',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];
    }

    getProducto(id: number){
        this.dataService = Constantes.servicios[id-1];
        document.getElementById("descripcion-s").innerHTML = this.dataService.des;
        let listaServicios = Constantes.servicios;
        let imgProducto = listaServicios[id-1].img;

        this.galleryImages.push(
            {
                small:  'assets/' + imgProducto,
                medium: 'assets/' + imgProducto,
                big:    'assets/' + imgProducto
            }
        ) 

    }
}
