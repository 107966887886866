import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Constantes } from 'src/app/utils/Constantes';

@Component({
  selector: 'app-service-section',
  templateUrl: './service-section.component.html',
  styleUrls: ['./service-section.component.scss']
})
export class ServiceSectionComponent implements OnInit {

    paginaCompleta : Boolean = true;
    listaServicios;
    
    constructor(@Inject(DOCUMENT) document: any) {
        //console.log(document.location.href);
    }

    ngOnInit() {
        let url = document.location.href;
        this.listaServicios = Constantes.servicios;

        if(!url.includes("/servicios")){
            this.paginaCompleta = false;
            this.listaServicios = this.listaServicios.slice(0,4);
        }
        
    }
}
