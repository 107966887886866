import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent {
    registerForm!: FormGroup;
    submitted = false;
    paginaCompleta : Boolean = true;

    get f() { return this.registerForm.controls; }

    constructor(private formBuilder: FormBuilder, @Inject(DOCUMENT) document: any) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstName: [''],
            lastName: [''],
            email: [''],
            product: [''],
        });

        let url = document.location.href;

        if(!url.includes("/contacto")){
            this.paginaCompleta = false;
        }
    }

    onSubmit() {

        const urlWhatsap = "https://api.whatsapp.com/send?phone=529993392479&text="
        let urlMsg:String = "Hola!%20Quisiera%20más%20información%20sobre%20sus%20servicios!"
        this.submitted = true;
        let name = this.registerForm.value.firstName;
        let lname = this.registerForm.value.lastName;
        let email = this.registerForm.value.email;
        let product = this.registerForm.value.product;

        if(name !== "" || lname !== ""){
            urlMsg += "%20Mi%20nombre%20es";
            urlMsg = this.addMsgToUrl(name, urlMsg, false);
            urlMsg = this.addMsgToUrl(lname, urlMsg, true);
        }

        if(email !== ""){
            urlMsg += "%20Pueden%20comunicarse%20conmigo%20por%20medio%20del%20correo";
            urlMsg = this.addMsgToUrl(email, urlMsg, true);
        }

        if(product !== ""){
            urlMsg += "%20Estoy%20interesado%20especificamente%20en%20su%20servicio%20de";
            urlMsg = this.addMsgToUrl(product, urlMsg, true);
        }
        
        window.open(urlWhatsap + urlMsg, "_blank");
    }

    addMsgToUrl(text: String, url: String, punto: boolean){
        let arrayText = text.split(" ");
        console.log(arrayText);
        arrayText.forEach(word => {
            url += "%20" + word;
        });
        if(punto === true){
            url += ".";
        }
        return url;
    }

    onReset() {
        this.submitted = false;
        this.registerForm.reset();
    }
}
