import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Constantes } from 'src/app/utils/Constantes';

@Component({
  selector: 'app-evidence-section',
  templateUrl: './evidence-section.component.html',
  styleUrls: ['./evidence-section.component.scss']
})
export class EvidenceSectionComponent {
    paginaCompleta : Boolean = true;
    listaObras;
    
    constructor(@Inject(DOCUMENT) document: any) {
        console.log(document.location.href);
    }

    ngOnInit() {
        let url = document.location.href;
        this.listaObras = Constantes.obras;

        if(!url.includes("/obras")){
            this.paginaCompleta = false;
            this.listaObras = this.listaObras.slice(0,3);
        }   
    }
}
