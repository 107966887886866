import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promo-comercial',
  templateUrl: './promo-comercial.component.html',
  styleUrls: ['./promo-comercial.component.scss']
})
export class PromoComercialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
