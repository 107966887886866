import { Component } from '@angular/core';

@Component({
  selector: 'app-inicio-section',
  templateUrl: './inicio-section.component.html',
  styleUrls: ['./inicio-section.component.scss']
})
export class InicioSectionComponent {
  title = 'barrangan-glass';
}
