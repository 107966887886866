export class Constantes {

    public static get productos() {
        return [
            {
                id: 1,
                titulo: "Parsol Verde",
                previewdes: "Este vidrio arquitectónico de color verde está diseñado para reducir la transmisión",
                des: `<p class="des des-prdct accmcolor">Este vidrio arquitectónico de color verde está diseñado para reducir la transmisión de luz solar y minimizar el deslumbramiento en interiores. Además, su acabado translúcido proporciona privacidad y seguridad, ya que dificulta la visibilidad desde el exterior.<br/><br/>Espesores: 6 y 10 mm<br/>Sugerencias de uso:<br/>-Barandales<br/>-Canceles<br/>-Fachadas</p>`,
                img: "parsolverde.jpeg"
            },
            {
                id: 2,
                titulo: "Parsol Gris o filtrasol",
                previewdes: "Este vidrio arquitectónico de color gris es similar al Parsol Verde, pero su acabado es más oscuro",
                des: `<p class="des des-prdct accmcolor">Este vidrio arquitectónico de color gris es similar al Parsol Verde, pero su acabado es más oscuro, lo que lo hace ideal para aplicaciones que requieren una mayor reducción de la transmisión de luz solar. Además, su acabado translúcido proporciona privacidad y seguridad, y su apariencia moderna y elegante lo hace perfecto para un ambiente fresco y minimalista.<br/><br/>Espesores: 6 y 10 mm<br/>Usos sugeridos:<br/>-Domos<br/>-Puertas para clóset<br/>-Barandales</p>`,
                img: "filtrasol.jpeg"
            },
            {
                id: 3,
                titulo: "Planilux o vidrio claro",
                previewdes: "Este vidrio arquitectónico claro y transparente es ideal para aplicaciones que ",
                des: `<p class="des des-prdct accmcolor">Este vidrio arquitectónico claro y transparente es ideal para aplicaciones que requieren una alta transmisión de luz solar y una visibilidad clara desde el interior y el exterior. El vidrio Planilux es resistente y duradero, lo que lo hace adecuado para todo tipo de aplicaciones arquitectónicas.<br/><br/>Espesores: 6, 10 y 12 mm<br/>Usos sugeridos:<br/>-Fachadas<br/>-Barandales<br/>-Canceles<br/>-Domos<br/>-Puertas de acceso</p>`,
                img: "claroobiseladotemplado.jpeg"
            },
            {
                id: 4,
                titulo: "Timeless",
                previewdes: "Es un vidrio arquitectónico que tiene una superficie lisa y uniforme lo que ayuda",
                des: `<p class="des des-prdct accmcolor">Es un vidrio arquitectónico que tiene una superficie lisa y uniforme lo que ayuda a crear un efecto de brillo y luminosidad en interiores. El vidrio Timeless es resistente y duradero, ya que gracias a que su cubierta no se encuentra teñida, no es reflectiva y su apariencia es similar a la de un vidrio transparente, la vuelve ideal para canceles de baño y mamparas.<br/><br/>Espesores: 6 y 10 mm<br/>Caracaterísticas:<br/>-Evita la corrosión<br/>-Evita las mancha<br/>-Permanece brillante<br/>-Garantía de 8 años</p>`,
                img: "timeless.jpeg"
            },
            {
                id: 5,
                titulo: "EVO 50 y 67",
                previewdes: "Es un vidrio ideal para el sector residencial, ofrece proteccón a los interiores",
                des: `<p class="des des-prdct accmcolor">Es un vidrio ideal para el sector residencial, ofrece proteccón a los interiores del desgaste, ya que disminiye la entrada de rayos UV. Sumado al proceso de Duo-Vent, es asilante térmico y acústico, lo que lo hace ideal para lugares cálidos o con alto índice de contaminación auditiva. El EVO 50 y 67, brinda un perfecto equilibrio para que la iluminación de los lugares sea armónica.<br/><br/>Espesores: 6 mm</p>`,
                img: "evo.jpeg"
            },
            {
                id: 6,
                titulo: "Sol-Lite",
                previewdes: "Es un vidrio que ofrece privacidad, está diseñado para controlar la cantidad",
                des: `<p class="des des-prdct accmcolor">Es un vidrio que ofrece privacidad, está diseñado para controlar la cantidad de luz y calor que entra en un espacio. Este vidrio cuenta con una capa de control solar que evita la exposición de los rayos UV, así como el desgaste de los muebles y cortinas, además es un vidrio con tecnología térmica lo que ayuda a mantener el interior fresco y confortable durante los días calurosos.<br/><br/>Disponibles en colores: Claro, gris, verde y bronce<br/>Espesores: 6 y 10 mm<br/>Sugerencias de uso:<br/>-Ventanas<br/>-Domos</p>`,
                img: "sollite.jpeg"
            }
        ];
    }

    //Cambiar las fotos de product por service...
    public static get servicios() {
        return [
            {
                id: 1,
                titulo: "Esmerilado de vidrio",
                previewdes: "El esmerilado de vidrio es un servicio que ofrece la posibilidad de personalizar",
                des: `<p class="des des-srvc accmcolor">El esmerilado de vidrio es un servicio que ofrece la posibilidad de personalizar y dar estilo a una gran variedad de proyectos arquitectónicos. Con este servicio, se puede obtener un acabado mate, translúcido o decorativo en el vidrio, lo que le brinda una apariencia elegante y sofisticada. Además, el esmerilado del vidrio también puede ser utilizado para proporcionar privacidad en ventanas o puertas de vidrio.</p>`,
                img: "esmerilado.jpeg"
            },
            {
                id: 2,
                titulo: "Cantos pulidos",
                previewdes: "El canto pulido es un proceso que le da al vidrio una apariencia más limpia",
                des: `<p class="des des-srvc accmcolor">El canto pulido es un proceso que le da al vidrio una apariencia más limpia y profesional. Consiste en pulir los bordes del vidrio para que queden redondeados y sin filo.</p>`,
                img: "cantopulido.jpeg"
            },
            {
                id: 3,
                titulo: "Templado",
                previewdes: "El templado es un proceso que otorga seguridad, se produce mediante el calentamiento",
                des: `<p class="des des-srvc accmcolor">El templado es un proceso que otorga seguridad, se produce mediante el calentamiento del vidrio a altas temperaturas y luego enfriándolo rápidamente. Esto crea un vidrio más resistente y duradero que el vidrio normal, lo que lo hace ideal para su uso en edificios y estructuras. Además, en caso de ruptura, se rompe en pequeños fragmentos sin bordes afilados, lo que reduce el riesgo de lesiones.</p>`,
                img: "claroobiseladotemplado.jpeg"
            },
            {
                id: 4,
                titulo: "Serigrafía",
                previewdes: "La serigrafía es un proceso de impresión en el que se utiliza una malla para transferir",
                des: `<p class="des des-srvc accmcolor">La serigrafía es un proceso de impresión en el que se utiliza una malla para transferir tinta a una superficie. Se pueden crear diseños personalizados o logotipos utilizando esta técnica, y es comúnmente utilizada para agregar detalles decorativos o funcionales a ventanas y puertas de vidrio. La diferencia con la impresión digital, es que los diseños deben ser más sencillos y de un mismo color.</p>`,
                img: "serigrafia.jpeg"
            },
            {
                id: 5,
                titulo: "Impresión digital",
                previewdes: "Para proyectos más personalizados, la impresión digital en vidrio te permite imprimir",
                des: `<p class="des des-srvc accmcolor">Para proyectos más personalizados, la impresión digital en vidrio te permite imprimir cualquier diseño o imagen directamente en la superficie del vidrio. Ideal para aplicaciones decorativas o publicitarias.</p>`,
                img: "impresiondigital.jpeg"
            },
            {
                id: 6,
                titulo: "Laminado",
                previewdes: "El laminado es un tipo de proceso de seguridad que se compone de dos o más capas",
                des: `<p class="des des-srvc accmcolor">El laminado es un tipo de proceso de seguridad que se compone de dos o más capas de vidrio unidas por una capa intermedia de plástico. Esta capa intermedia actúa como una barrera de seguridad adicional, lo que hace que el vidrio sea más resistente y evita que los fragmentos se dispersen en caso de ruptura, es acústico, adicionalmente puede ser craquelado y/o decorativo.</p>`,
                img: "laminado.jpeg"
            },
            {
                id: 7,
                titulo: "Biselado",
                previewdes: "El biselado es un proceso de corte y pulido que se utiliza para crear bordes",
                des: `<p class="des des-srvc accmcolor">El biselado es un proceso de corte y pulido que se utiliza para crear bordes angulares y facetados en el vidrio. Esta técnica puede ser utilizada para agregar un toque decorativo a espejos y vidrios de ventanas.<br/><br/>Disponible en las siguientes medidas: 0.5", 1", 1.5" y 2"</p>`,
                img: "claroobiseladotemplado.jpeg"
            },
            {
                id: 8,
                titulo: "Duo-Vent",
                previewdes: "El sistema Duo-Vent es un proceso aislante que se compone de dos o más capas de vidrio",
                des: `<p class="des des-srvc accmcolor">El sistema Duo-Vent es un proceso aislante que se compone de dos o más capas de vidrio separadas por un sellador. Este espacio actúa como una barrera térmica, lo que significa que el vidrio puede ayudar a mantener una temperatura más constante en los interiores. Además, el sistema Duo-Vent puede reducir el ruido exterior y la entrada de polvo y otros contaminantes.</p>`,
                img: "duo.jpeg"
            }
        ];
    }

    //Cambiar las fotos de product por obra...
    public static get obras() {
        return [
            {
                id: 1,
                titulo: "Obra 1",
                previewdes: "Es un producto que no solo responde a las necesidades estéticas, si no.",
                des: `<p class="des des-obra accmcolor">Es un producto que no solo responde a las necesidades estéticas, si no. También a las relacionadas con la protección y seguridad.El uso del cristal templado hace posible la marcada tendencia en la arquitectura actual(sustentable), de cubrir claros de cristal, con cada vez menos elementos de apoyo solo pequeños herrajes). De esta manera el cristal templado puede emplearse en fachadas suspendidas puertas de acceso, canceles de baño, y otras donde la seguridad sea especificada.</p>`,
                img: "product1-1.jpeg"
            },
            {
                id: 2,
                titulo: "Obra 2",
                previewdes: "Es un proceso de desbaste a ciertos grados, dándole la elegancia al",
                des: `<p class="des des-obra accmcolor">Es un proceso de desbaste a ciertos grados, dándole la elegancia al terminado, tanto en vidrios, como en espejos.<br/><br/> Podemos biselar en vidrios rectos como en forma, en piezas de 5 mm hasta 19mm, los tamaños de bisel que manejamos va desde ½ pulgada hasta 2 pulgadas</p>`,
                img: "product1-2.jpeg"
            },
            {
                id: 3,
                titulo: "Obra 3",
                previewdes: "Es un proceso de desbaste a ciertos grados, dándole la elegancia al",
                des: `<p class="des des-obra accmcolor">Es un proceso de desbaste a ciertos grados, dándole la elegancia al terminado, tanto en vidrios, como en espejos.<br/><br/> Podemos biselar en vidrios rectos como en forma, en piezas de 5 mm hasta 19mm, los tamaños de bisel que manejamos va desde ½ pulgada hasta 2 pulgadas</p>`,
                img: "product1-3.jpeg"
            }
        ];
    }
}