import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { Constantes } from 'src/app/utils/Constantes';

@Component({
  selector: 'app-detail-evidence',
  templateUrl: './detail-evidence.component.html',
  styleUrls: ['./detail-evidence.component.scss']
})
export class DetailEvidenceComponent {
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];
    dataObra;

    constructor(private route: ActivatedRoute) {};
 
    ngOnInit(): void {

        this.route.params.subscribe(params => {
            this.getProducto(params['id']);
        });
 
        this.galleryOptions = [
            {
                width: '100%',
                height: '70vh',
                thumbnailsColumns: 5,
                imageAnimation: NgxGalleryAnimation.Zoom,
                imageAutoPlayInterval: 3000,
                previewCloseOnEsc: true,
                previewCloseOnClick: true,
                imageAutoPlay: true, 
                imageAutoPlayPauseOnHover: true, 
                previewAutoPlay: false,
                imageSwipe: true, 
                thumbnailsArrows: true, 
                thumbnailsSwipe: true,
                previewSwipe: true 
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '50vh',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];
    }

    //Cambiar la ruta por obra n-n etc...
    getProducto(id: number){
        this.dataObra = Constantes.obras[id-1];
        document.getElementById("descripcion-o").innerHTML = this.dataObra.des;
        for (let index = 0; index < 5; index++) {
            const numberImg = index + 1;
            //small:  'assets/product' + id + '-' + numberImg + '.jpeg',
            this.galleryImages.push(
                {
                    small:  'assets/product1-' + numberImg + '.jpeg',
                    medium: 'assets/product1-' + numberImg + '.jpeg',
                    big:    'assets/product1-' + numberImg + '.jpeg'
                }
            )
        }
        console.log(this.galleryImages);
    }
}
